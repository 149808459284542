import * as React from "react";
import Main from "@components/main";
import data from "@/data/main-en";
import { Helmet } from "react-helmet";

const IndexPageEn = () => (
  <>
    <Helmet>
      <meta name="facebook-domain-verification" content="ar4mlub9t7iko190dx4qsqxlchdabz" />
    </Helmet>
    <Main data={data} lang={'en'} pageTitle={'Foretell'} />
  </>
);

export default IndexPageEn;
